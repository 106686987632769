<template>
  <div>
    v0.0.7
    <div v-for="[itemKey, item] of Object.entries(res)" :key="`res-${itemKey}`">
      key = {{ itemKey }} || value = {{ item }}
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);
import "@/static/js/smartInstall.min.js";
// import smartInstall from "@/device.js";
export default {
  name: "Device",
  components: {},
  data: function () {
    return {
      apiData: {},
      res: {},
    };
  },
  async mounted() {
    var getUrlString = location.href;
    var url = new URL(getUrlString);
    var referralCode = url.searchParams.get("referralCode")
      ? url.searchParams.get("referralCode")
      : "";
    // appkey
    // UAT => zLEXg1a2eU
    // dev => c4ca4238
    // let collectParams = {
    //   appKey: "c4ca4238",
    //   apiKey: `${"Web"}:${"o1vikV8Iqd"}`,
    //   referralCode: referralCode,
    // };
    const smartInstall = new window.SmartInstall();
    await smartInstall.init("zLEXg1a2eU");
    // await smartInstall.download(referralCode);
    this.res = await smartInstall.collect(referralCode);
    console.log("res => ", this.res);
  },
};
</script>

<style lang="scss" scoped></style>
